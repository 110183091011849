import React from "react";

const isAndroid = navigator.userAgent.indexOf("Android") !== -1;

function App() {
  const onPressGoogle = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=fr.marhold.splitit&hl=fr"
    );
  };
  const onPressApple = () => {
    window.open(
      "https://apps.apple.com/app/splitit-comptes-entre-ami-e-s/id6480421720"
    );
  };

  return (
    <div className="app">
      <h1 className="h1">DiviZ</h1>
      <div className="main-row">
        <img onClick={onPressGoogle} src="playstore.svg" alt="playstore_logo" />
        {!isAndroid && (
          <img onClick={onPressApple} src="appstore.svg" alt="appstore_logo" />
        )}
      </div>
    </div>
  );
}

export default App;
